import { createMultiPartFormBody } from "../util/Util";
import { ApiKey, ApiUrl, SessionKey, ThemeOption } from "../util/Constant";
import { showResponseMessage, userKeepLogin, _SET_THEME_NAME } from "./AppAction";
import ApiEngine from "../util/ApiEngine.js";
import { _SAVE_LANGUAGE_ID } from "./LanguageAction";
import store from "../ApplicationStore";
import { find } from "lodash";
import { setRolloverTarget, setPlayableCategory, setCurrentRolloverBalance, setProductCategory } from "../redux/GameAction.js";

export const _LOGIN_SUCCESS = "LoginSuccess";
export const _LOGIN_FAILED = "LoginFailed";
export const _LOGOUT = "Logout";
export const _GET_SHOP_CREDIT = "GetShopCredit";
export const _UPDATE_USER_DATA = "UpdateUserData";

/// <summary>
/// Author :
/// </summary>
export function getShopCredit() {
  return async (dispatch, getState) => {
    var userData = getState().authState.userData;

    if (userData) {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_ACCOUNT + "?id=" + userData.userId
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        var shopData = responseJson[ApiKey._API_DATA_KEY];

        dispatch({
          creditLimit: shopData.shopProfile
            ? shopData.shopProfile.creditLimit
            : 0,
          type: _GET_SHOP_CREDIT,
        });
      }
    }
  };
}

/// <summary>
/// Author :
/// expose login action as function to be called from classes
/// </summary>
export function performLogin(username, password, isKeepedLogin, selectedLanguage, selectedTheme) {
  return async (dispatch) => {
    dispatch({
      type: _LOGOUT,
    });
    dispatch(userKeepLogin(isKeepedLogin));
    let params = {
      username: username,
      password: password,
      isKeepedLogin: isKeepedLogin,
      selectedLanguage: selectedLanguage,
      selectedTheme: selectedTheme
    };

    var responseJson = await ApiEngine.post(
      ApiUrl._API_LOGIN,
      createMultiPartFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      var userData = responseJson[ApiKey._API_DATA_KEY]["userData"];
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: "login",
            jwtToken: responseJson[ApiKey._API_DATA_KEY]["jwtToken"],
            userId: userData["userId"]
          })
        );
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: "change-theme",
            themeId: userData["selectedTheme"],
          })
        );
      }
      dispatch({
        type: _LOGIN_SUCCESS,
        userData: userData,
      });

      localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, true);
    } else {
      dispatch({
        type: _LOGIN_FAILED,
        message: responseJson[ApiKey._API_MESSAGE_KEY],
      });
      dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  };
}

/// <summary>
/// Author :
/// this function is to only update user status to login if it is already considered authenticated in cookie
/// </summary>
export const updateLoginUser = (userData) => {
  return {
    type: _LOGIN_SUCCESS,
    userData: userData,
  };
};

/// <summary>
/// Author :
/// </summary>
export const updateUserData = () => {
  return async (dispatch) => {
    var responseJson = await ApiEngine.get(ApiUrl._API_UPDATE_USER_DATA);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      dispatch({
        type: _UPDATE_USER_DATA,
        userData: responseJson[ApiKey._API_DATA_KEY],
      });
    }
  };
};

/// <summary>
/// Author :
/// expose logout action as function to be called from classes
/// </summary>
export const performLogout = () => {
  return async (dispatch) => {
    var responseJson = await ApiEngine.post(ApiUrl._API_LOGOUT);

    await responseJson;
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ message: "Succesfully logged out.", code: "LOGOUT" })
      );
    }

    localStorage.removeItem('isShowMiniGameTrigger');
    localStorage.removeItem('isOnload');
    localStorage.removeItem('6FAZhVi1QvBGsKaUl7WPhVTwRPf1HUhF');
    dispatch({
      type: _LOGOUT,
    });
  };
};

/// <summary>
/// Author :
/// this function is to only update user status to logout
/// </summary>
export const resetLogin = () => {
  return async (dispatch) => {
    dispatch({
      type: _LOGOUT,
    });
  };
};

export const forceLogout = () => {
  return async (dispatch) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ message: "Succesfully force logged out.", code: "FORCE_LOGOUT" })
      );
    }

    localStorage.removeItem('isShowMiniGameTrigger');
    localStorage.removeItem('isOnload');

    dispatch(showResponseMessage(false, "FORCED_LOGOUT", true));
    dispatch({
      type: _LOGOUT,
    });
  };
};

/// <summary>
/// Author : -
/// </summary>
export const checkIsLoggedIn = () => {
  return async (dispatch, getState) => {
    var isKeepedLogin = getState().appState.isKeepedLogin;
    const { productCategory } = store.getState().gameState;
    let params = {
      isKeepedLogin: isKeepedLogin,
    };

    var responseJson = await ApiEngine.get(ApiUrl._API_IS_LOGGED_IN); //, createMultiPartFormBody(params));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      dispatch(updateLoginUser(responseJson[ApiKey._API_DATA_KEY]));


      const appTheme = find(
				ThemeOption,
				(item) =>
					item.themeId === responseJson[ApiKey._API_DATA_KEY].selectedTheme
			);

			if (appTheme) {
				const { value } = appTheme;

				if (store.getState().appState.themeName != value) {
					dispatch({
						type: _SET_THEME_NAME,
						themeName: value,
					});
				}
			}


      if (!store.getState().languageState.retain) {
        dispatch({
          type: _SAVE_LANGUAGE_ID,
          languageId: responseJson[ApiKey._API_DATA_KEY].languageSetting,
        });
      } else {
        let data = {};
        data["languageSetting"] = store.getState().languageState.languageId;

        await ApiEngine.post(
          ApiUrl._API_UPDATE_MEMBER_LANGUAGE,
          createMultiPartFormBody(data)
        ).then((response) => {
          if (response[ApiKey._API_SUCCESS_KEY]) {
            dispatch({
              type: _SAVE_LANGUAGE_ID,
              retain: false,
            });
          }
        });
      }

      if (responseJson[ApiKey._API_DATA_KEY].isSpecialRollover) {
        const specialRollover = responseJson[ApiKey._API_DATA_KEY].isSpecialRollover;
        dispatch(setCurrentRolloverBalance(responseJson[ApiKey._API_DATA_KEY].rolloverBalance));
        dispatch(getRolloverData());
        dispatch(getProductCategory());
        if (specialRollover && Object.keys(productCategory).length !== 0) {
          dispatch(getBonusDepositProductCategory());
        }
      }

    } else if (!responseJson[ApiKey._API_SUCCESS_KEY] && responseJson[ApiKey._API_MESSAGE_KEY] == "FORCE_LOGOUT") {
      dispatch(forceLogout());
    } else {
      dispatch(resetLogin());
    }
  };
};

export const getRolloverData = () => {
  return async (dispatch) => {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLLOVER_RECORD);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      dispatch(setRolloverTarget(data[0].rolloverTarget));
    }
  }

}
export const getBonusDepositProductCategory = () => {
  return async (dispatch) => {
    const { productCategory } = store.getState().gameState;
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let productCategoryArr = [];

      if (data.includes(",")) {
        productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
      }
      else {
        productCategoryArr.push(data);
      }

      let tempArr = [];
      productCategory.forEach((x) => {
        if (productCategoryArr.includes(x.value)) {
          tempArr.push(x.originalTitle);
        }
      })
      dispatch(setPlayableCategory(tempArr));
    }
  }

}

export const getProductCategory = () => {
  return async (dispatch) => {
    let tempArr = [];
    var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
      let data = productCategoryJson[ApiKey._API_DATA_KEY];
      data.forEach(x => {
        if (x.id > 0) {
          tempArr.push({ value: x.id.toString(), label: x.title, originalTitle: x.originalTitle });
        }
      });
      dispatch(setProductCategory(tempArr))
    }
  }
}
